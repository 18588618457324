<template>
  <div class="steps">
    <p>
      <span v-bind:class="{ 'step-number-success': currentStep > 1 }"
            class="step-number">
        <template v-if="step1Completed"><img src="../assets/img/mdi-check.svg"></template>
        <template v-if="!step1Completed">1</template>
      </span>
      <span class="step-text">Autenticação</span>
    </p>
    <hr>
    <p>
      <span v-bind:class="{ 'step-number-success': currentStep > 2, 'step-number-current': currentStep == 2, 'step-number-disabled': currentStep < 2 }"
            class="step-number">
        <template v-if="currentStep > 2"><img src="../assets/img/mdi-check.svg"></template>
        <template v-if="currentStep <= 2">2</template>
      </span>
      <span v-bind:class="{ 'step-text-current': currentStep == 2 }" class="step-text">Pixel na loja</span>
    </p>
    <hr>
    <p>
      <span v-bind:class="{ 'step-number-success': currentStep > 3, 'step-number-current': currentStep == 3, 'step-number-disabled': currentStep < 3 }"
            class="step-number">
        <template v-if="currentStep > 3"><img src="../assets/img/mdi-check.svg"></template>
        <template v-if="currentStep <= 3">3</template>
      </span>
      <span v-bind:class="{ 'step-text-current': currentStep == 3 }"
          class="step-text">Produto</span>
    </p>
    <hr>
    <p>
      <span v-bind:class="{ 'step-number-success': currentStep > 4, 'step-number-current': currentStep == 4, 'step-number-disabled': currentStep < 4 }"
            class="step-number">{{ currentStep > 4 ? '&#10004;' : '4' }}</span>
      <span v-bind:class="{ 'step-text-current': currentStep == 4 }"
          class="step-text">Criar campanha</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'WizardStep',
  props: ['step'],
  data: function () {
    return {
      storeId: this.store,
      payload: null
    }
  },
  computed: {
    currentStep: function () {
      return this.step;
    },
    step1Completed: function () {
      return this.step > 1;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.wizard-step {
  align-self: center;
}

h1 {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 3rem auto;
}

div.steps {
  align-items: center;
  align-self: center;
  display: flex;
}

div.steps p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -2em;
  margin-right: -2em;
}

div.steps hr {
  border-top: 2px solid #BEC4CA;
  margin-top: -2rem;
  width: 6em;
}

span.step-number {
  align-self: center;
  border-radius: 50%;
  display: inline-block;
  color: #FFFFFF;
  font-size: 1rem;
  height: 2rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 0.7rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
  width: 2rem;
}

span.step-text {
  align-self: center;
  color: #6C757D;
  font-size: 0.9rem;
  min-width: 8rem;
  text-align: center;
}

span.step-number-success {
  background-color: #1ABC9C;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
}

span.step-number-current {
  background-color: #008FFB;
}

span.step-number-disabled {
  background-color: rgba(50, 58, 70, 0.2);
}

span.step-text-current {
  color: #323A46;
  font-weight: bold;
}
</style>
