<template>
  <div id="container" class="container">
    <div class="conteudo">
      <div class="warning">
        <p><i class="far fa-exclamation-triangle"></i></p>
        <p>Todas as atualizações dos produtos no seu painel Tray serão atualizadas automaticamente no TikTok.</p>
      </div>
      <p>Já estamos enviando seus produtos para o TikTok.</p>
      <p>Após o envio o TikTok avalia e libera seus produtos para criar sua campanha.</p>
      <p style="font-weight: bold">Este processo pode levar até 24 horas para ser concluído pelo TikTok.</p>
      <div class="buttons">
        <button class="back" style="visibility: hidden">Voltar</button>
        <button v-on:click="nextStep()" class="btn btn-primary">
          <template v-if="productsSent">Avançar</template>
          <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
        </button>
      </div>
    </div>
    <alert-error v-if="error">
      <template v-slot:alert-error>
        {{msgError}}
      </template>
    </alert-error>
  </div>
</template>

<script>
export default {
  name: 'WizardProduto',
  mounted() {
    let storeId = this.$cookies.get('Authorization')

    this.$http.get('tray/products/start', { headers: {'Authorization': storeId} })
        .then(() => {
          this.sendingProducts = false
        }).catch(error => {
      if (error.response) {
        console.log(error.response.status);
        if (error.response.status == 404) {
          window.location = 'https://www.tray.com.br'
        }
        this.msgError = error.response.data.message;
        this.error = true;
      }
    });
  },
  data: function () {
    return {
      sendingProducts: true,
      error: false,
      msgError: ""
    }
  },
  computed: {
    productsSent: function () {
      return !this.sendingProducts
    }
  },
  methods: {
    nextStep() {
      if (this.productsSent) {
        this.$router.push(`#campanha`)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  flex-direction: column;
}

div.warning {
  background: #F1F5F7;
  border-radius: 4px;
  display: flex;
  margin-bottom: 1.5em;
}

div.warning p {
  color: #296775;
  margin: 1em;
}

div.warning p:first-of-type {
  align-self: center;
  margin-right: 0;
}

div.buttons {
  align-self: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-right: -6rem;
  margin-top: 2rem;
}

div.buttons button.back {
  background-color: #FFFFFF;
  border: 1px solid #6C757D;
  color: #6C757D;
}
</style>
