<template>
  <popup-modal ref="popup">
    <div class="top">
        <button @click="_cancel" class="close-button"><b-icon-x></b-icon-x></button>
      <p>{{ title }}</p>
    </div>
    <div class="body">
      <div class="msg-account">
        <p>{{ message }}</p>
      </div>
      <div class="msg-alert-o">
        <div class="msg-extra-html" v-html="messageExtra"></div>
      </div>
      <div class="msg-error">
        <p>{{msgError}}</p>
      </div>
    </div>
    <div class="bottom">

      <div class="buttons-buttom">
        <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
        <button class="ok-btn" @click="_confirm">{{ okButton }}</button>
      </div>
    </div>

  </popup-modal>
</template>


<script>
import PopupModal from './PopupModal.vue'

export default {
  name: 'ConfirmDialogue',

  components: { PopupModal },

  data: () => ({
    title: undefined,
    message: undefined,
    messageExtra: undefined,
    msgError: undefined,
    okButton: undefined,
    cancelButton: 'Cancelar',

    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.messageExtra = opts.messageExtra
      this.msgError = opts.msgError
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      this.$refs.popup.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
     // this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
    },
  },
}
</script>

<!-- components/ConfirmDialogue.vue -->

<style scoped>
.buttons-buttom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.ok-btn {
  background-color: #F1556C;
  border-radius: 2.4px;
  margin-right: 12px;
  width: 144.8px;
  height: 35.4px;

  color: #FFFFFF;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  box-shadow: none;
  border: none #F1556C;

}

.cancel-btn {
 margin-left: 12px;
  width: 83.8px;
  height: 35.4px;
  background: #FFFFFF;
  color: #6C757D;
  border: 1px solid #6C757D;
  box-sizing: border-box;
  border-radius: 2.4px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.top{
  position: relative;
  height: 55px;
  padding-top: 16px;
  border-bottom: 1px solid #DEE2E6;
}
.top p{
  margin-left: 16px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #323A46;
}

.body{
  height: 200px;
}

.msg-account{
  width: 468px;
  height: 63px;
  background: #F9F9F9;
  border-radius: 4px;
  display: table;
  padding: 20px;
  margin: 16px 16px 30px;
}

.msg-account p{
  vertical-align: middle;
  display: table-cell;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #6C757D;
}

.msg-alert-o{
  width: 468px;
  height: 61px;
  background: #FCDDE2;
  border-radius: 3px;
  border: 1px solid #FBCFD6;
  margin: 16px;
  display: table;
  padding-left: 12px;
}

.msg-extra-html{
  vertical-align: middle;
  display: table-cell;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #7D2C38;
}

.msg-error{
  width: 468px;
  border-radius: 3px;
  margin: 16px;
  display: table;
  padding-left: 12px;
}

.msg-error p{
  vertical-align: middle;
  display: table-cell;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #7D2C38;
}

.bottom{
  border-top: 1px solid #DEE2E6;
  height: 63px;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #6C757D;
  background: #FFFFFF;
  border: 0px solid;
}
</style>