<template>
  <div>
    <div id="container" class="container">
      <div class="conteudo">
        <div>
          <p style="color: #1ABC9C; font-weight: 600">
            Sua conta
            <b>
              <template v-if="accountNumber">{{ emailAddress }} ({{ accountNumber }})</template>
              <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
            </b>
            foi ativa com sucesso!
          </p>
          <p>
            Foi instalado em sua loja o pixel
            <span style="font-weight: bold">
              <template v-if="pixelNumber">{{ pixelNumber }}</template>
              <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
            </span>.
          </p>
          <p>Com este pixel será possível o TikTok ter uma inteligência maior para apresentar seus produtos de acordo com o perfil do seu cliente, além de gerar relatórios de performance mais precisos.</p>
        </div>
        <div class="buttons">
          <b-button v-if="accountNumber"  v-on:click="doDelete" variant="outline-light">Desconectar conta</b-button>
          <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          <b-button v-on:click="nextStep()" variant="primary">Avançar</b-button>
        </div>
      </div>
        <alert-error v-if="error">
          <template v-slot:alert-error>
            {{msgErrorAlert}}
          </template>
        </alert-error>
    </div>
  </div>
</template>

<script>
import ConfirmDialogue from './ConfirmDialogue.vue'

export default {
  name: 'WizardPixel',
  components: { ConfirmDialogue },
  mounted() {
    document.title = 'Pixel na loja'

    let storeId = this.$cookies.get('Authorization')

    this.$http.get('wizard/pixel', { headers: {'Authorization': storeId} })
        .then(res => {
          this.bcId = res.data.bc_id
          this.pixel = res.data.pixel
          this.email = res.data.email
        }).catch(error => {
      if (error.response) {
        console.log(error.response.status);
        this.msgErrorAlert = error.response.data.message;
        this.error = true;
      }
    });
  },
  data: function () {
    return {
      bcId: null,
      pixel: null,
      email: null,
      error: false,
      msgErrorAlert: ""
    }
  },
  computed: {
    accountNumber: function () {
      return this.bcId
    },
    pixelNumber: function () {
      return this.pixel
    },
    emailAddress: function () {
      return this.email
    },
    htmlReturningFn: function ()  {
      return `Após desconectar sua conta todos os dados <b>serão possíveis ser visualizados APENAS no TikTok.</b>`
    }
  },
  methods: {
    async doDelete() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Desconectar conta?',
        message: this.email + " ("+ this.accountNumber + ")",
        messageExtra: this.htmlReturningFn,
        okButton: 'Desconectar conta',
        msgError: ''
      })

      if (ok) {
        this.$refs.confirmDialogue.okButton = 'Desconectando...'
        let storeId = this.$cookies.get('Authorization')
        this.$http.post('/tiktok/disconnect', { }, {headers: {'authorization': storeId} })
            .then((res) => {
              this.$refs.confirmDialogue.okButton = "Conta desconectada"
              this.$refs.confirmDialogue.msgError = ''
              window.parent.location.href = res.data.urlCallback + '/admin/onboarding/dashboard';
            }).catch(error => {
          if (error.response) {
            this.$refs.confirmDialogue.msgError = 'Falha ao desconectar sua conta'
            console.log(error.response.data.message)
            this.$refs.confirmDialogue.okButton = 'Desconectar conta';
          }
        })
      }
    },
    nextStep() {
      this.$router.push(`#produto`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  flex-direction: column;
}

h1 {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 3rem auto;
}

div.buttons {
  align-self: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-right: -6rem;
  margin-top: 2rem;
}

div.buttons button.back {
  background-color: #FFFFFF;
  border: 1px solid #6C757D;
  color: #6C757D;
}
</style>
