<template>
  <div>
    <TikTokLogo/>
    <div id="container" class="container">
      <h1>
        Olá,
        <template v-if="clientName">{{ clientName }}!</template>
        <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
        Falta pouco para anunciar no TikTok
      </h1>
      <WizardStep v-bind:step="currentStep" style="margin-bottom: 2em"/>
      <WizardPixel v-if="currentStep==2"/>
      <WizardProduto v-if="currentStep==3"/>
      <WizardCampanha v-if="currentStep==4"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TikTokLogo from "@/components/TikTokLogo";
import WizardStep from "@/components/WizardStep.vue";
import WizardPixel from "@/components/WizardPixel.vue";
import WizardProduto from "@/components/WizardProduto.vue";
import WizardCampanha from "@/components/WizardCampanha.vue";

export default {
  name: 'Home',
  components: {
    TikTokLogo,
    WizardStep,
    WizardPixel,
    WizardProduto,
    WizardCampanha,
  },
  beforeMount() {
    let storeId = this.$cookies.get('Authorization')

    if (null == storeId || storeId.length == 0) {
       window.location = 'https://www.tray.com.br'
    }

    // let isTikTokCallback = this.$route.query.state ? true : false

    this.$http.get('wizard/pixel', { headers: {'Authorization': storeId} })
        .then((res) => {
          this.username = res.data.name
          if (this.$route.hash != '#pixel') {
            this.$router.push({path: '/wizard', hash: '#pixel'})
          } else {
            this.step = 2;
          }
        }).catch(error => {
      if (error.response) {
        // if (!isTikTokCallback) {
          this.$router.push({path: '/'})
        // }
      }
    });

    // if (isTikTokCallback) {
    //   let storeId = this.$cookies.get('Authorization')
    //
    //   let payload = {
    //     "auth_code": this.$route.query.auth_code,
    //     "code": this.$route.query.code,
    //     "state": storeId
    //   }
    //
    //   this.$http.get('tiktok/auth/callback', { params: payload })
    //       .then(() => {
    //         this.step = 2
    //         this.$router.push({ path: '/wizard', hash: '#pixel'})
    //       }).catch(error => {
    //     if (error.response) {
    //       console.log(error.response.message)
    //       // this.mensagem = error.response.data.message
    //       this.$router.push('/error')
    //     }
    //   });
    // }

  },
  data: function () {
    return {
      step: 2,
      username: ''
    }
  },
  computed: {
    currentStep: function () {
      return this.step
    },
    clientName: function () {
      return this.username
    }
  },
  watch: {
    $route(to) {
      switch (to.hash) {
        case '#campanha':
          this.step = 4;
          break;
        case '#produto':
          this.step = 3;
          break;
        case '#pixel':
          this.step = 2;
          break;
        default:
          this.step = 1;
      }
    }
  },
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  flex-direction: column;
}

h1 {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 3rem auto;
}
div.conteudo {
  align-self: center;
  max-width: 1000px;
}

div.buttons {
  align-self: center;
  display: flex;
  min-width: 1100px;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-top: 2rem;
}

div.buttons button {
  border-radius: 0.2rem;
  padding: 0.2rem 0.8rem;
}

div.buttons button.back {
  background-color: #FFFFFF;
  border: 1px solid #6C757D;
  color: #6C757D;
}
</style>
