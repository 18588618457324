<template>
  <div id="container" class="container">
    <div class="conteudo">
      <p>Está quase tudo pronto para começar anunciar seus produtos no TikTok.</p>
      <p>
        Você pode aguardar o TikTok liberar todos os seus produtos (até 24 horas) ou
        já pode criar uma nova campanha convidando os clientes para conhecer sua loja.
      </p>
      <p class="create-campaign-button">
        <button v-on:click="openCampaign()" v-if="!campaignsLoaded">
          <i class="fas fa-spinner fa-pulse"></i>
        </button>
        <button v-on:click="openCampaign()" v-if="campaignsLoaded && !hasCampaigns" class="btn btn-primary">
          Criar nova campanha
        </button>
      </p>
      <div v-if="campaignsLoaded && !hasCampaigns">
        1. Clique no botão acima para Criar sua campanha<br>
        2. Preencha todos os dados e crie / envie seu vídeo para o TikTok<br>
        3. Clique no final da tela para ENVIAR sua campanha<br>
        4. Volte para esta tela e clique em <strong>AVANÇAR</strong> para concluir sua integração com o TikTok<br>
        5. PRONTO!
      </div>
      <div class="alert" v-if="campaignsLoaded && hasCampaigns">
         <p id="msg-campaign">Você já possui uma ou mais campanhas em sua conta TikTok.<br>
           <b>Clique em AVANÇAR</b> para finalizar sua integração.</p>
      </div>
      <div class="buttons">
        <button class="back" style="visibility: hidden">Voltar</button>
        <button v-on:click="nextStep()" v-if="campaignsLoaded" v-bind:class="{ disabled: !hasCampaigns }" class="btn btn-primary">
          Avançar
        </button>
      </div>
      <alert-error v-if="error">
        <template v-slot:alert-error>
          {{msgError}}
        </template>
      </alert-error>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardCampanha',
  beforeMount() {
    this.storeId = this.$cookies.get('Authorization')
    this.getCampaigns()
    this.setGetCampaignsInterval()
    this.getApprovedProducts()
    this.setGetApprovedProductsInterval()
  },
  mounted() {
    document.title = 'Criar campanha'
  },
  data: function () {
    return {
      storeId: null,
      loadingCampaigns: true,
      campaigns: [],
      poolCampaignInterval: null,
      isPoolingCampaignInterval: false,
      poolApprovedProductsInterval: null,
      processedProducts: null,
      error: false,
      msgError: ""
    }
  },
  computed: {
    campaignsLoaded: function () {
      return !this.loadingCampaigns
    },
    hasCampaigns: function () {
      return this.campaigns.length > 0
    },
    approvedProducts: function () {
      return (null === this.processedProducts) ? 0 : this.processedProducts
    }
  },
  methods: {
    setGetCampaignsInterval() {
      let timeInterval = 30 * 1000; // 30 segundos
      this.poolCampaignInterval = setInterval(this.getCampaigns, timeInterval)
    },
    getCampaigns() {
      if (!this.isPoolingCampaignInterval) {
        this.isPoolingCampaignInterval = true
        this.$http.get('tiktok/campaigns', {headers: {'Authorization': this.storeId}})
            .then(res => {
              this.isPoolingCampaignInterval = false
              this.loadingCampaigns = false
              this.campaigns = res.data
              if (this.hasCampaigns) {
                clearInterval(this.poolCampaignInterval)
              }
            }).catch(error => {
          if (error.response) {
            console.log(error.response.status);
            this.loadingCampaigns = false
            this.msgError = error.response.data.message;
            this.error = true;
          }
        })
      }
    },
    openCampaign() {
      let storeId = this.$cookies.get('Authorization')

      this.$http.get('tiktok/campaigns/creation/url', { headers: {'Authorization': storeId} })
          .then(res => {
            window.open(res.data.url, "campaign");
          }).catch(error => {
            if (error.response) {
              console.log(error.response.status);
            }
            this.msgError = error.response.data.message;
            this.error = true;
          })
    },
    setGetApprovedProductsInterval() {
      let timeInterval = 30 * 1000; // 30 segundos
      this.poolApprovedProductsInterval = setInterval(this.getApprovedProducts, timeInterval)
    },
    getApprovedProducts() {
      let storeId = this.$cookies.get('Authorization')

      this.$http.get('tray/requests/check', { headers: {'Authorization': storeId} })
          .then(() => {
            this.$http.get('tray/requests/verify', { headers: {'Authorization': storeId} })
                .then(res => {
                  this.processedProducts = res.data.processed_products
                  console.log(this.approvedProducts)
                }).catch(error => {
              if (error.response) {
                console.log(error.response.status);
                this.msgError = error.response.data.message;
                this.error = true;
              }
            })
          }).catch(error => {
        if (error.response) {
          console.log(error.response.status);
          this.msgError = error.response.data.message;
          this.error = true;
        }
      })
    },
    nextStep() {
      if (this.hasCampaigns) {
        let storeId = this.$cookies.get('Authorization')

        this.$http.get('wizard/finish', { headers: {'Authorization': storeId} })
            .then(() => {
              this.$router.push({name: 'PaginaInicial'})
            }).catch(error => {
          if (error.response) {
            console.log(error.response.status);
            this.msgError = error.response.data.message;
            this.error = true;
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  flex-direction: column;
}

div.conteudo p:first-of-type {
  font-size: 1.1em;
}

p.create-campaign-button {
  margin-top: 2.5em;
  text-align: center;
}
/*
.create-campaign-button button {
  border-radius: 0.2rem;
  padding: 0.2rem 0.8rem;
}
*/
div.buttons {
  align-self: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-right: -7rem;
  margin-top: 2rem;
}
/*
div.buttons button {
  border-radius: 0.2rem;
  padding: 0.2rem 0.8rem;
}

div.buttons button.disabled {
  background-color: #63C0F6;
  border: 1px solid #53B0E6;
}
*/
div.buttons button.back {
  background-color: #FFFFFF;
  border: 1px solid #6C757D;
  color: #6C757D;
}

div.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  height: 62px;
  left: 140px;
  top: 325px;

  background: #F1F5F7;
  border-radius: 4px;

  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 48px 0px;
}

p#msg-campaign {
  position: static;
  width: 560px;
  height: 38px;
  left: calc(50% - 560px / 2);
  top: 12px;

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #296775;

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 12px 20px 12px 20px;
}

</style>
